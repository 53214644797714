export enum FileUploadTypes {
  PASSPORT = 'passport',
  CONSENT = 'consent',
  EDUCATION_AWARD = 'education_award',
  EDUCATION_CERTIFICATE = 'education_certificate',
  JOB_OFFER = 'job_offer',
  VERIFY_REPORT = 'verify_report',
  ATTACHMENT = 'comment_attachment',
  INSTITUTE_REPORT = 'education_institute_report',
  SLA_HOLD_ATTACHMENT = 'sla_hold_attachments',
  SP_BANK_ACCOUNT_CONFIRMATION = 'sp_bank_account_confirmation',
  SP_LICENSE_FILE = 'sp_license_file',
  REPORT_INVOICE = 'report_invoice',
  APOSTILLE_CERTIFICATE = 'apostille_certificate',
  ADDITIONAL_PAYMENT_REQUEST = 'additional_payment_request',
  FAST_TRACK_REQUEST_DOCUMENT = 'fast_track_request_document',
}

export enum FileUploadKeys {
  FILE_ID = 'fileId',
  ENTITY_ID = 'entityId',
  CONTEXT_ID = 'contextId',
  TYPE = 'type',
  FILE = 'file',
}

export interface FileDetails {
  name: string;
  id: string;
  path: string;
  size: number;
  fileId: string;
  userId: string;
  isOld: boolean;
  createdAt: string;
  updatedAt: string;
  previousFile?: FileDetails;
}

export interface FileDetailPayload {
  fileId?: string;
  entityId?: string;
  contextId?: string;
  type: string;
}

export default FileUploadTypes;
