export function setContentSecurityPolicyHeader(hyperpayUrl: string, apiUrl: string, checkoutId: string | undefined) {
  const metaTag = document.getElementById('csp-meta');
  if (!metaTag) return;
  metaTag.setAttribute('content', `
          style-src 'self' ${hyperpayUrl} 'unsafe-inline';

          frame-src 'self' ${hyperpayUrl} https://www.google.com/;

          script-src
            'self'
            'unsafe-eval'
            ${hyperpayUrl}
            nonce-${checkoutId};

          connect-src
            'self'
            ${hyperpayUrl}
            ${apiUrl}
            https://p11.techlab-cdn.com;

          img-src 'self' ${hyperpayUrl} data:;

          worker-src 'self' blob:;
          object-src 'none';
      `);
}
