import { LogType, LogTypeMap } from '@/common/types';

export const logsDetailsMap: LogTypeMap = {
  [LogType.START_VERIFICATION]: { helperKey: 'startVerification', icon: 'autorenew', iconColor: '#666666' },
  [LogType.REJECT_HOLD]: { helperKey: 'rejectHold', icon: 'autorenew', iconColor: '#666666' },
  [LogType.CONSENT_WITHDRAWN]: { helperKey: 'consentWithdrawn', icon: 'block', iconColor: '#ffffff' },
  [LogType.REQUEST_CREATE]: { helperKey: 'requestCreate', icon: 'mail_outline', iconColor: '#ffffff' },
  [LogType.REQUEST_FOR_UPDATE]: { helperKey: 'requestForUpdate', icon: 'insert_comment', iconColor: '#ffffff' },
  [LogType.REQUEST_HOLD]: { helperKey: 'requestHold', icon: 'autorenew', iconColor: '#666666' },
  [LogType.REQUEST_RESUME]: { helperKey: 'requestResume', icon: 'autorenew', iconColor: '#666666' },
  [LogType.REQUEST_REASSIGN]: { helperKey: 'requestReassign', icon: 'swap_horiz', iconColor: '#666666' },
  [LogType.REQUEST_VERIFIED]: { helperKey: 'requestVerified', icon: 'done', iconColor: '#ffffff' },
  [LogType.REQUEST_REJECTED]: { helperKey: 'requestUnableToVerify', icon: 'close', iconColor: '#ffffff' },
  [LogType.REQUEST_APPEALED]: { helperKey: 'requestAppealed', icon: 'replay', iconColor: '#ffffff' },
  [LogType.REQUEST_UPDATED]: { helperKey: 'requestUpdated', icon: 'insert_comment', iconColor: '#ffffff' },
  [LogType.REQUEST_TO_IN_PROGRESS]: { helperKey: 'requestToInProgress', icon: 'insert_comment', iconColor: '#ffffff' },
  [LogType.REQUEST_EDITED]: { helperKey: 'requestEdited', icon: 'insert_comment', iconColor: '#ffffff' },
};

export default logsDetailsMap;
